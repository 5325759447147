import "./Footer.css";
import React from "react";
import { Container, Row } from "react-bootstrap";

const FooterInstance = (
  <div className="footer">
    <Container fluid>
      <Row>
        <div className="textbox">
          <Container fluid className="mainText">
            Camp Izza is hosted by Rahma Center with federal tax ID 85-3255194
          </Container>
          <Container fluid>
            <a href="https://www.campizza.com">www.campizza.com</a> • (949)
            422-8123
          </Container>
        </div>
      </Row>
    </Container>
  </div>
);

export default FooterInstance;
